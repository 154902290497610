<template>
	<v-navigation-drawer :clipped="true" mobile-breakpoint="0" app :value="drawer" expand-on-hover class="navigation-drawer">
		<v-list dense class="sidebar-block">
			<template v-for="item in filteredItems">
				<v-row v-if="item.heading" :key="item.heading" align="center">
					<v-col cols="6">
						<v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
					</v-col>
					<v-col cols="6" class="text-center">
						<a href="#!" class="body-2 black--text">EDIT</a>
					</v-col>
				</v-row>
				<v-list-group v-else-if="item.children" :key="item.text" v-model="item.model" :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title>{{ item.text }}</v-list-item-title>
						</v-list-item-content>
					</template>
					<v-list-item v-for="(child, i) in item.children" :key="i" link :to="child.to" :href="child.href" :target="child.href ? '_blank' : ''">
						<v-list-item-action v-if="child.icon" class="pl-6 mr-2">
							<v-icon>{{ child.icon }}</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>
								{{ child.text }}
								<span v-if="child.href">
									&nbsp;&nbsp;
									<v-icon small>mdi-open-in-new</v-icon>
								</span>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
				<div v-else-if="item.subheader" :key="item.id">
					<v-subheader :key="item.header" inset>
						{{ item.header }}
					</v-subheader>
				</div>
				<div v-else-if="item.divider" :key="item.id">
					<v-divider inset />
					<!-- <v-subheader inset>Folders</v-subheader> -->
				</div>
				<v-list-item v-else :key="item.text" link :to="item.to">
					<v-list-item-action class="mr-4">
						<!-- <v-badge v-if="item.badge" color="red" :content="badgeContent(item.badge)">
							<v-icon :color="item.color">{{ item.icon }}</v-icon>
						</v-badge> -->
						<v-icon :color="item.color">{{ item.icon }}</v-icon>
					</v-list-item-action>
					<v-list-item-content color="primary">
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-list>
	</v-navigation-drawer>
</template>

<script lang="ts">
import User from "@/model/user";
import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import router from "@/router";
import { RouteConfig } from "vue-router";

@Component
export default class Settings extends Vue {
	@Getter drawer!: boolean;
	@Getter("menuItems") items!: Array<any>;
	@Getter("getStoreUser") storeUser!: User;

	get allowedRoutes(): RouteConfig[] | undefined {
		return router?.options?.routes?.filter((pr) => {
			if (pr.name === "Home") {
				return true;
			}
			return this.storeUser.isRouteAllowed(pr.path.split("/")[1]);
		});
	}

	get filteredItems(): Array<any> {
		const filtered = this.items.filter((item) => {
			if (item.divider) {
				return true;
			} else if (item.children) {
				item.children = item.children.filter((ci: any) => this.allowedRoutes?.find((ar) => ci.to?.name === ar.name));
				return item.children.length > 0;
			} else {
				return this.allowedRoutes?.find((ar) => item.to?.name === ar.name);
			}
		});

		return filtered;
	}

	badgeContent(badge: string): string {
		// Used to display the contents of badge (like number of notifications remaining to be seen)
		return "";
	}
}
</script>

<style>
.navigation-drawer {
	z-index: 100 !important;
}
.v-navigation-drawer__content {
	overflow-y: auto !important;
}
</style>
