.home-container {
    height: calc(100vh - 36px);
    display: flex;
    flex-direction: column;
}

/* position */
.position-relative {
    position: relative;
}

.position-static {
    position: static;
}

.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

.position-sticky {
    position: sticky;
}

.bottom-0 {
    bottom: 0;
}

.flex-none {
    flex: none;
}

.v-input__prepend-inner {
    margin-top: 16px;
}

.g-2 {
    gap: 0.5rem;
}

.fill-width {
    width: 100%;
}

.fill-maxwidth {
    max-width: 100%;
}

.login-image {
    min-width: 100px;
    width: 100px;
}

/* sidebar */
.sidebar-block {
    flex: 0 1 auto
}

/* prices */
.vertical-panes.price-panes .pane.pane-list {
    min-width: 150px;
    width: 256px;
    max-width: 356px;
}

.vertical-panes.price-panes .multipane-resizer {
    margin-left: -6px;
}

/* prices  end */

/* resizer */

.vertical-panes.multipane .multipane-resizer {
    /* devider */
    background-color: #d2d9dd;
    margin-left: -6px;
}

.readonly-style {
    border: none;
    padding-left: 15px;
}


.max-h-400 {
    max-height: 400px;
}

.height-200 {
    height: 200px;
}

.max-height-200 {
    max-height: 200px;
}

/* Global dropdown */
.v-menu__content.v-autocomplete__content {
    display: flex;
    flex-direction: column;
}

.v-menu__content.v-autocomplete__content .v-list.v-select-list {
    position: relative;
    min-width: 260px;
    height: 100%;
    background-color: #ffffff;
}

/* tree listing */
.tree-listing {
    max-height: 304px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 304px;
    overflow-y: auto;
    top: -16px;
    position: relative;
    z-index: 205;
    background-color: #ffffff;
    box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1rem;
}

.tree-listing .v-treeview-node__root {
    min-height: 40px;
}

.tree-listing.tree-listing-block .v-treeview-node__label {
    display: none;
}

.tree-listing .v-treeview-node__root.v-treeview-node--active.primary--text {
    color: #1e1e1e !important;
    caret-color: #1e1e1e !important;
}

.modal-body-container {
    min-height: 470px;
}